import React from "react"
import { Image, Box } from "@theme-ui/components"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade, Zoom, Thumbs } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/zoom"
import "swiper/css/autoplay"
import "swiper/css/thumbs"
import { CLOUDINARY_BASE } from "../constants"

const BlogPostCarousel = ({ photos = [] }) => {
  //   const demoPhotos = [
  //     "https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  //     "https://images.pexels.com/photos/1070945/pexels-photo-1070945.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     "https://images.pexels.com/photos/2246476/pexels-photo-2246476.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     "https://images.pexels.com/photos/1036657/pexels-photo-1036657.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     "https://images.pexels.com/photos/1542252/pexels-photo-1542252.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //   ]
  const photosNormalized = photos.map(photo => ({
    url: `${CLOUDINARY_BASE}/${photo.cloudinaryId}`,
    seo: photo.name,
  }))
  return (
    <Swiper
      modules={[Autoplay, EffectFade, Zoom, Thumbs]}
      zoom
      slidesPerView={1}
      autoplay={{ delay: 5000, effect: "fade" }}
      speed={2000}
      effect="fade"
      loop
    >
      {photosNormalized.map(photo => (
        <SwiperSlide key={photo.url}>
          <Box>
            <Image
              src={photo.url}
              alt={photo.seo}
              sx={{ width: "100%", objectFit: "cover", maxHeight: 500 }}
            />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default BlogPostCarousel
