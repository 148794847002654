import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import slugify from "slugify"
import { Image, Heading, Text, Flex, Box } from "theme-ui"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { CLOUDINARY_BASE } from "../constants"
import { cloudinaryOptimizer, getCloudinaryImage } from "../helpers"
import Container from "../components/Container"
import dayjs from "dayjs"
import BlogPostCarousel from "../components/BlogPostCarousel"

var Scroll = require("react-scroll")
var Element = Scroll.Element

const BlogTemplate = ({ location, data }) => {
  const {
    blog: { media, title, description, author, createdAt, bodyHTML, subtitle },
  } = data

  const createMarkup = () => {
    return { __html: bodyHTML }
  }

  useEffect(() => {
    setTimeout(() => {
      // Somewhere else, even another file
      Scroll.animateScroll.scrollTo(75)
    }, 500)
  }, [])

  return (
    <Layout location={location} title={title}>
      <Seo
        title={title}
        description={description}
        img={`${CLOUDINARY_BASE}/${media[0].cloudinaryId}`}
      />
      <Element name="scrollTo">
        <Container width={900}>
          <Flex sx={{ justifyContent: "center", my: 4 }}>
            <Box
              sx={{
                width: 200,
                height: "2px",
                backgroundColor: "primary",
              }}
            />
          </Flex>
          <Heading
            as="h1"
            sx={{
              fontSize: [3, 3, 3, 5, 6],
              fontWeight: 400,
              mt: 4,
              mb: 4,
              px: [2, 2, 0],
              textAlign: "center",
            }}
          >
            {title}
          </Heading>
          <Heading
            as="h2"
            sx={{
              fontSize: [1, 1, 1, 2, 3],
              textAlign: "center",
              mb: 4,
              color: "muted",
              fontWeight: 300,
              px: [2, 2, 0],
            }}
          >
            {subtitle}
          </Heading>
        </Container>

        <Container width={1200}>
          <Flex sx={{ justifyContent: "center", width: "100%", mb: 5 }}>
            {media.length > 1 ? (
              <Container width={900}>
                <BlogPostCarousel photos={media} />
              </Container>
            ) : (
              <Image
                sx={{ maxHeight: 500 }}
                src={cloudinaryOptimizer(
                  getCloudinaryImage(media[0].cloudinaryId, "1200")
                )}
              ></Image>
            )}
          </Flex>
        </Container>
        <Container width={800}>
          <Text
            sx={{
              fontSize: 2,
              px: [2, 2, 0],

              a: {
                color: "primary",
                fontSize: 2,
              },
            }}
            as="p"
          >
            {dayjs(createdAt).format("MMMM DD, YYYY")} | By{" "}
            <Link to={`/author/${slugify(author, { lower: true })}`}>
              {author}
            </Link>{" "}
          </Text>
          <Box sx={markupStyles} dangerouslySetInnerHTML={createMarkup()} />
        </Container>
      </Element>
    </Layout>
  )
}

export default BlogTemplate

const markupStyles = {
  pb: 5,
  px: [2, 2, 0],
  p: {
    lineHeight: 2,
    mb: 3,
    fontSize: 2,
  },
}

export const query = graphql`
  query BlogPost($id: String) {
    blog(id: { eq: $id }) {
      title
      subtitle
      media {
        cloudinaryId
        description
        name
      }
      readingTime
      tags
      id
      description
      ctaLink {
        url
        title
      }
      createdAt
      bodyHTML
      body
      author
    }
  }
`
